<template>
  <div>
    <div class="d-flex justify-center">
      <v-form
        autocomplete="off"
        class="pt-2 campaign-form"
        @submit.prevent="send()"
        style="width: 630px"
      >
        <v-card max-width="630" min-width="300" class="pb-4">
          <v-progress-linear
            v-if="files.length && (files[0].active || progress !== 0)"
            v-model="progress"
            color="secondary"
          ></v-progress-linear>
          <v-card-title class="px-7">
            <div class="font-weight-bold text-subtitle-1">
              <div class="secondary-font">
                {{ $t("bulksms.campaign.file.create.title") }}
              </div>
            </div>
          </v-card-title>
          <v-card-subtitle class="grey--text px-7 text-body-2">{{
            $t("bulksms.campaign.file.create.description")
          }}</v-card-subtitle>
          <v-card-text class="px-7 pb-2 pt-2">
            <div>
              <div class="mb-3 text-body-2 font-weight-bold">
                {{ $t("bulksms.campaign.fields.file.help") }}
              </div>
            </div>
            <file-upload
              :custom-action="send"
              :extensions="extensions"
              :accept="accept"
              :multiple="multiple"
              :directory="directory"
              :size="size"
              :thread="thread"
              :headers="headersUpload"
              :data="form"
              :drop="drop"
              :drop-directory="dropDirectory"
              :add-index="addIndex"
              v-model="files"
              @input-filter="inputFilter"
              @input-file="inputFile"
              ref="upload"
              :disabled="$refs.upload && $refs.upload.active"
            >
              <div class="d-flex flex-wrap align-center mb-1">
                <div class="ml-0 mr-2">
                  <v-btn
                    :color="isFiles ? 'secondary' : 'primary'"
                    class="mb-1"
                  >
                    <v-icon left>mdi-cloud-upload-outline</v-icon>
                    <span v-if="!isFiles">{{
                      $t("bulksms.campaign.fields.file.title")
                    }}</span>
                    <span v-else>{{
                      $t("bulksms.campaign.fields.file.selected")
                    }}</span>
                  </v-btn>
                </div>
                <div
                  v-if="isFiles"
                  class="font-weight-bold grey--text text--darken-1 text-body-2 mb-1"
                >
                  <span :title="files[0].name">{{
                    $utils.truncate(files[0].name, 50)
                  }}</span>
                  <span>- {{ $utils.formatSize(files[0].size) }}</span>
                </div>
              </div>
            </file-upload>
            <div class="error--text font-weight-bold" v-if="isFileError">
              {{ getFileErrors(files[0]) }}
            </div>
            <div class="d-flex justify-end mt-3">
              <div>
                <a
                  @click.prevent="downloadSampleFile()"
                  class="font-weight-bold grey--text text--darken-2 text-body-2"
                  >{{ $t("bulksms.campaign.file.sample.title") }}</a
                >
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card max-width="630" min-width="300" class="mt-2">
          <v-card-actions>
            <v-spacer></v-spacer>
            <div>
              <v-btn
                color="danger"
                v-if="$refs.upload && $refs.upload.active"
                @click="cancelPush()"
              >
                <v-icon left class="white--text">mdi-cancel</v-icon>
                <span class="white--text">{{ $t("btn.cancel") }}</span>
              </v-btn>
              <v-btn
                color="primary"
                :loading="loading.upload"
                @click="$refs.upload.active = true"
                :disabled="!isValidForm"
                type="button"
                class="mr-0 ml-2"
                >{{ $t("btn.send") }}</v-btn
              >
            </div>
          </v-card-actions>
        </v-card>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  metaInfo() {
    return {
      title: this.$t("bulksms.campaign.file.create.title"),
    };
  },
  data: () => ({
    form: {
      maskNumber: false,
    },
    extensions: "csv",
    accept: "text/csv",
    multiple: false,
    directory: false,
    size: 1024 * 1024 * 10,
    minSize: 1,
    thread: 1,
    headersUpload: {},
    drop: false,
    dropDirectory: false,
    addIndex: false,
    files: [],
    cancelSource: null,
    loading: {
      upload: false,
    },
    progress: 0,
  }),
  methods: {
    async send(file, component) {
      // define cancel source
      let cancelToken = this.axios.CancelToken;
      this.cancelSource = cancelToken.source();

      // hide old alert
      this.notify({ status: false });

      // validate form
      if (!this.isValidForm) {
        // display error message
        let message = this.$t("error_codes.bad_request");
        this.notify({ message: message });
        return;
      }

      // validate status bulksms user
      if (this.accountIsDisabled) {
        this.notify({ message: this.$t("bulksms.error.disabled") });
        return;
      }

      // validate credit
      if (!this.creditIsSufficient) {
        this.notify({ message: this.$t("bulksms.error.deficience_credit") });
        return;
      }

      this.loading.upload = true;
      try {
        await this.upload(file, component);
        this.resetForm();
        this.notify({
          message: this.$t("bulksms.campaign.file.create.success"),
        });
      } catch (error) {
        let message = "error_codes.error_occured";
        if (error && error.response) {
          const status = error.response.status;
          const data = error.response.data;
          if (status === 422 && data.code === "P101") {
            if (data.details.code === 422 || data.details.code === 400) {
              message = data.details.message;
            }
          }
        }
        this.$refs.upload.update(file, {
          error: message,
        });
      }
      this.loading.upload = false;
    },
    async upload(file) {
      const data = new FormData();
      data.append("file", file.file);

      return this.request({
        url: "/api/providers/bulksms/pushes/file",
        method: "POST",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: this.onUploadProgress,
        cancelToken: this.cancelSource.token
      });
    },
    cancelPush() {
      if (this.cancelSource) {
        this.cancelSource.cancel("Operation canceled");
        this.$refs.upload.active = false;
        this.loading.upload = false;
      }
    },
    downloadSampleFile() {
      this.$utils.downloadSampleFile();
    },
    inputFilter(newFile, oldFile) {
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
      }
    },
    // add, update, remove File Event
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // hide alert message
        this.notify({ status: false });

        if (!/\.(csv)$/i.test(newFile.name)) {
          this.$refs.upload.update(newFile, { error: "extension" });
        }
      }
      if (newFile && oldFile) {
        // reset progress
        this.progress = 0;
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (newFile.size >= 0 && newFile.size < this.minSize) {
            this.$refs.upload.update(newFile, {
              error: "minSize",
            });
          }
        }
        if (newFile.error && !oldFile.error) {
          // error
          let message = this.getFileErrors(newFile);
          if (message) {
            this.notify({ message: message });
          }
        }
      }
    },
    onUploadProgress(progressEvent) {
      this.progress = Math.floor(
        (progressEvent.loaded / progressEvent.total) * 100
      );
    },
    getFileErrors(file) {
      let message;
      switch (file.error) {
        case "minSize":
          message = this.$t("bulksms.campaign.fields.file.minSize");
          break;
        case "size":
          message = this.$t("bulksms.campaign.fields.file.maxSize", {
            size: this.$utils.formatSize(this.size),
          });
          break;
        case "extension":
        case "only csv allowed":
          message = this.$t("bulksms.campaign.fields.file.extension");
          break;
        case "timeout":
          message = this.$t("bulksms.campaign.fields.file.timeout");
          break;
        case "abort":
          message = this.$t("bulksms.campaign.fields.file.abort");
          break;
        case "network":
          message = this.$t("bulksms.campaign.fields.file.network");
          break;
        case "server":
          message = this.$t("error_occured");
          break;
        case "denied":
          message = this.$t("bulksms.campaign.fields.file.denied");
          break;
        case "file is required":
          message = this.$t("bulksms.campaign.fields.file.required");
          break;
        case "error_codes.bad_request":
          message = this.$t("error_codes.bad_request");
          break;
        case "error_codes.forbidden":
          message = this.$t("error_codes.forbidden");
          break;
        case "error_codes.error_occured":
          message = this.$t("error_codes.error_occured");
          break;
        default:
          message = file.error;
      }

      return message;
    },
    resetForm() {
      this.$refs.upload.clear();
      this.progress = 0;
    },
    ...mapActions({
      request: "request",
      notify: "notification/notify",
    }),
  },
  computed: {
    ...mapGetters({
      accountIsDisabled: "bulksms/userIsDisabled",
      creditIsSufficient: "bulksms/creditIsSufficient",
      bulksmsUser: "bulksms/user",
    }),
    isFiles() {
      return !this._.isEmpty(this.files);
    },
    uploadInProgress() {
      return this.files.length && (this.files[0].active || this.progress !== 0);
    },
    isFileError() {
      return this.files.length && this.files[0].error;
    },
    isValidForm() {
      if (!this.files.length || this.files[0].error) {
        return false;
      }
      return true;
    },
  },
};
</script>