<template>
  <div>
    <campaign-file></campaign-file>
  </div>
</template>

<script>
import CampaignFile from './../../../components/bulksms/campaigns/file';
export default {
  data: () => ({
  }),
  components: {
    CampaignFile
  }
}
</script>